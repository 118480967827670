import { useQuery } from '@apollo/client';
import { useTheme } from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { internalClientIds, JobType } from 'shared/common/job-constants';

import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { SearchTextField } from '../common/search-text-field';
import { BILLINGS_LEADERBOARD } from '../graphql/queries/recruiter-stats';
import { LeaderboardBilling } from '../graphql/queries/recruiter-stats';
import { useLocalStorage } from '../hooks/use-local-storage';
import { CellValue, DataPanel, DataPanelColumn, dataPanelContainerStyles, selectorStyles } from './data-panel';
import { DurationSelector } from './duration-selector';
import { Header } from './header';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';

type Column = 'recruiter' | 'recruiterBillings' | 'amBillings' | 'totalBillings' | 'commission';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'recruiterBillings', label: 'Recruiter Billings' },
    { id: 'amBillings', label: 'AM Billings' },
    { id: 'totalBillings', label: 'Total Billings' },
    { id: 'commission', label: 'Commission' }
];

const displayedColumns = columns.filter((column) => column.id !== 'totalBillings');

export const Leaderboard: React.FC = () => {
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'quarter', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('leaderboard-job-types', jobTypesForFilter.get('all'));
    const [searchText, setSearchText] = React.useState('');
    const { data } = useQuery<
        { leaderboard: LeaderboardBilling[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(BILLINGS_LEADERBOARD, {
        variables: {
            endTime: duration.end,
            excludeClientIds: internalClientIds,
            jobType: jobTypes,
            startTime: duration.start
        }
    });

    const getCellValue = (column: Column) => (billing: LeaderboardBilling) => {
        switch (column) {
            case 'recruiter':
                return billing.name;
            case 'recruiterBillings':
                return billing.recruiterBillings;
            case 'amBillings':
                return billing.amBillings;
            case 'totalBillings':
                return billing.totalBillings;
            case 'commission':
                return Math.round(billing.commission);
            default:
                return null;
        }
    };

    const getCellRenderer = {
        amBillings: (value: CellValue) => value.toLocaleString(),
        commission: (value: CellValue) => value.toLocaleString(),
        recruiterBillings: (value: CellValue) => value.toLocaleString(),
        totalBillings: (value: CellValue) => value.toLocaleString()
    };

    const rowFilter = React.useCallback(
        (billing: LeaderboardBilling) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                getCellValue('recruiter')(billing)?.toString().match(searchRegex) ||
                getCellValue('recruiterBillings')(billing)?.toString().match(searchRegex) ||
                getCellValue('amBillings')(billing)?.toString().match(searchRegex) ||
                getCellValue('totalBillings')(billing)?.toString().match(searchRegex) ||
                getCellValue('commission')(billing)?.toString().match(searchRegex)
            );
        },
        [searchText, getCellValue]
    );

    return (
        <DocumentTitle title="Leaderboard">
            <div id="container">
                <Header title="Leaderboard" />
                <div css={dataPanelContainerStyles(theme)}>
                    <div className="selectors">
                        <div css={selectorStyles}>
                            <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                            <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                            <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                        </div>
                    </div>
                    <div className="data-panel">
                        <DataPanel
                            columns={displayedColumns}
                            data={data?.leaderboard}
                            getCellValue={getCellValue}
                            getCellRenderer={getCellRenderer}
                            sortableColumns={['recruiterBillings', 'amBillings', 'totalBillings', 'commission']}
                            filterableColumns={['recruiter']}
                            initialSortColumn="commission"
                            initialSortDirection="desc"
                            rowFilter={rowFilter}
                            hidePagination={true}
                            pageSize={100}
                        />
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
