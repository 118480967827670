import { FunnelStats } from '../graphql/queries/recruiter-stats';

export interface Stat {
    value: number;
    periodBaseline: number;
    average: number;
}

export interface UserRecruitingStats {
    user: {
        id: string;
        name: string;
        username: string;
    };
    acceptOutreachRatio: Stat;
    outreachesSent: Stat;
    outreachResponseRatio: Stat;
    phoneScreens: Stat;
    submits: Stat;
    callAcceptRatio: Stat;
    clientAccepts: Stat;
    finalRounds: Stat;
    offers: Stat;
    hired: Stat;
    totalBillings: Stat;
    recruiterBillings: Stat;
    amBillings: Stat;
    commission: Stat;
}

const minPhoneScreens = 5; // minimum number of phone screens to count in averages calculation

export const percentageChange = (value: number, lastValue: number) =>
    lastValue ? Math.ceil(((value - lastValue) / lastValue) * 100) : undefined; // tslint:disable-line:no-magic-numbers

const statFromValue = (value: number): Stat => ({
    average: undefined,
    periodBaseline: undefined,
    value
});

const roundDecimal = (val: number) => Math.round(val * 100) / 100; // tslint:disable-line:no-magic-numbers

const getAcceptOutreachRatio = (record: FunnelStats['funnel'][number]) => {
    if (record.acceptOutreachRatio !== undefined && record.acceptOutreachRatio !== null) {
        return record.acceptOutreachRatio;
    } else if (!record.outreachesSent) {
        return undefined;
    } else {
        return (record.clientAccepts * 1000) / record.outreachesSent; // tslint:disable-line:no-magic-numbers
    }
};

export function getStatsMap(funnelData: FunnelStats, funnelBaselineData: FunnelStats) {
    const dataMap = new Map<string, UserRecruitingStats>();
    const totals = {
        acceptOutreachRatioSum: 0,
        acceptOutreachRecruiters: 0,
        amBillingsRecruiters: 0,
        amBillingsSum: 0,
        billingRecruiters: 0,
        billingSum: 0,
        clientAcceptsRecruiters: 0,
        clientAcceptsSum: 0,
        finalRoundsRecruiters: 0,
        finalRoundsSum: 0,
        hiredRecruiters: 0,
        hiredSum: 0,
        offersRecruiters: 0,
        offersSum: 0,
        outreachResponseRatio: 0,
        outreachResponseRatioRecruiters: 0,
        outreachesRecruiters: 0,
        outreachesSum: 0,
        phoneScreensRecruiters: 0,
        phoneScreensSum: 0,
        recruiterBillingsRecruiters: 0,
        recruiterBillingsSum: 0,
        submitsRecruiters: 0,
        submitsSum: 0,
        totalBillingsRecruiters: 0,
        totalBillingsSum: 0
    };

    for (const record of funnelData?.funnel ?? []) {
        const ratio =
            record.clientAccepts === 0
                ? 0
                : record.phoneScreens
                  ? roundDecimal(record.clientAccepts / record.phoneScreens)
                  : undefined;
        dataMap.set(record.user.id, {
            acceptOutreachRatio: statFromValue(getAcceptOutreachRatio(record)),
            amBillings: statFromValue(record.amBillings),
            callAcceptRatio: statFromValue(ratio),
            clientAccepts: statFromValue(record.clientAccepts),
            commission: statFromValue(record.commission),
            finalRounds: statFromValue(record.finalRounds),
            hired: statFromValue(record.hired),
            offers: statFromValue(record.offers),
            outreachResponseRatio: statFromValue(record.outreachResponseRatio),
            outreachesSent: statFromValue(record.outreachesSent),
            phoneScreens: statFromValue(record.phoneScreens),
            recruiterBillings: statFromValue(record.recruiterBillings),
            submits: statFromValue(record.submits),
            totalBillings: statFromValue(record.totalBillings),
            user: record.user
        });
        if (record.phoneScreens > minPhoneScreens) {
            totals.acceptOutreachRatioSum += getAcceptOutreachRatio(record) ?? 0;
            totals.totalBillingsSum += record.totalBillings;
            totals.recruiterBillingsSum += record.recruiterBillings;
            totals.amBillingsSum += record.amBillings;
            totals.clientAcceptsSum += record.clientAccepts;
            totals.phoneScreensSum += record.phoneScreens;
            totals.outreachesSum += record.outreachesSent;
            totals.submitsSum += record.submits;
            totals.finalRoundsSum += record.finalRounds;
            totals.hiredSum += record.hired;
            totals.offersSum += record.offers;
            totals.outreachResponseRatio += record.outreachResponseRatio;

            totals.acceptOutreachRecruiters++;
            totals.clientAcceptsRecruiters++;
            totals.totalBillingsRecruiters++;
            totals.recruiterBillingsRecruiters++;
            totals.amBillingsRecruiters++;
            totals.phoneScreensRecruiters++;
            totals.submitsRecruiters++;
            totals.outreachesRecruiters++;
            totals.offersRecruiters++;
            totals.finalRoundsRecruiters++;
            totals.hiredRecruiters++;
            totals.outreachResponseRatioRecruiters++;
        }
    }

    for (const record of funnelBaselineData?.funnel ?? []) {
        const existingRecord = dataMap.get(record.user.id);
        const ratio =
            record.clientAccepts === 0
                ? 0
                : record.phoneScreens
                  ? record.clientAccepts / record.phoneScreens
                  : undefined;
        if (existingRecord) {
            existingRecord.acceptOutreachRatio.periodBaseline = getAcceptOutreachRatio(record) ?? 0;
            existingRecord.totalBillings.periodBaseline = record.totalBillings;
            existingRecord.recruiterBillings.periodBaseline = record.recruiterBillings;
            existingRecord.amBillings.periodBaseline = record.amBillings;
            existingRecord.callAcceptRatio.periodBaseline = ratio;
            existingRecord.clientAccepts.periodBaseline = record.clientAccepts;
            existingRecord.phoneScreens.periodBaseline = record.phoneScreens;
            existingRecord.submits.periodBaseline = record.submits;
            existingRecord.outreachesSent.periodBaseline = record.outreachesSent;
            existingRecord.finalRounds.periodBaseline = record.finalRounds;
            existingRecord.offers.periodBaseline = record.offers;
            existingRecord.hired.periodBaseline = record.hired;
            existingRecord.outreachResponseRatio.periodBaseline = record.outreachResponseRatio;
        }
    }

    const acceptOutreachRatioAvg = totals.acceptOutreachRatioSum / (totals.acceptOutreachRecruiters || 1);
    const totalBillingsAvg = totals.totalBillingsSum / (totals.totalBillingsRecruiters || 1);
    const recruiterBillingsAvg = totals.recruiterBillingsSum / (totals.recruiterBillingsRecruiters || 1);
    const amBillingsAvg = totals.amBillingsSum / (totals.amBillingsRecruiters || 1);
    const clientAcceptAvg = totals.clientAcceptsSum / (totals.clientAcceptsRecruiters || 1);
    const submitAvg = totals.submitsSum / (totals.submitsRecruiters || 1);
    const phoneScreenAvg = totals.phoneScreensSum / (totals.phoneScreensRecruiters || 1);
    const outreachAvg = totals.outreachesSum / (totals.outreachesRecruiters || 1);
    const finalRoundsAvg = totals.finalRoundsSum / (totals.finalRoundsRecruiters || 1);
    const offersAvg = totals.offersSum / (totals.offersRecruiters || 1);
    const hiredAvg = totals.hiredSum / (totals.hiredRecruiters || 1);
    const callAcceptRatioAvg = totals.phoneScreensSum ? totals.clientAcceptsSum / totals.phoneScreensSum : 0;
    const outreachResponseRatioAvg = totals.outreachResponseRatio / (totals.outreachResponseRatioRecruiters || 1);

    dataMap.forEach((record) => {
        record.acceptOutreachRatio.average = acceptOutreachRatioAvg;
        record.totalBillings.average = totalBillingsAvg;
        record.recruiterBillings.average = recruiterBillingsAvg;
        record.amBillings.average = amBillingsAvg;
        record.clientAccepts.average = clientAcceptAvg;
        record.phoneScreens.average = phoneScreenAvg;
        record.outreachesSent.average = outreachAvg;
        record.submits.average = submitAvg;
        record.offers.average = offersAvg;
        record.finalRounds.average = finalRoundsAvg;
        record.hired.average = hiredAvg;
        record.callAcceptRatio.average = callAcceptRatioAvg;
        record.outreachResponseRatio.average = outreachResponseRatioAvg;
    });

    return { dataMap, totals };
}
