import { useMutation, useQuery } from '@apollo/client';
import { Button, useTheme } from '@material-ui/core';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { createDuration, defaultPeriods, Duration } from '../../common/duration';
import { SearchTextField } from '../../common/search-text-field';
import { client as apolloClient } from '../../graphql/apollo-client';
import {
    Commission,
    CREATE_COMMISSIONS,
    CreateCommissionsInput,
    GET_COMMISSIONS
} from '../../graphql/queries/commissions';
import { useModal } from '../../hooks/use-modal';
import { useSession } from '../../hooks/use-session';
import { useSnackbar } from '../../hooks/use-snackbar';
import { dataPanelContainerStyles, selectorStyles } from '../data-panel';
import { DurationSelector } from '../duration-selector';
import { NavigationSelect } from '../navigation-select';
import { accountingPageRoutes } from './accounting-page-header';
import { CommissionsBatchDialog } from './commissions-batch-dialog';
import { Column, CommissionsDataPanel } from './commissions-data-panel';

const payrollClient = apolloClient('payroll_admin');
const recruiterClient = apolloClient('recruiter');

export const Commissions: React.FC<{ accounting: boolean }> = ({ accounting }) => {
    const { userPermissions } = useSession();
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'year', offset: 0 }));
    const { data, refetch } = useQuery<{ commissions: Commission[] }, { startTime: number; endTime: number }>(
        GET_COMMISSIONS,
        {
            client: accounting ? payrollClient : recruiterClient,
            variables: {
                endTime: duration.end,
                startTime: duration.start
            }
        }
    );
    const [createCommissions] = useMutation<CreateCommissionsInput>(CREATE_COMMISSIONS, { client: payrollClient });
    const [searchText, setSearchText] = React.useState('');
    const [batchDialogData, setBatchDialogData] = React.useState<{
        commissions: Commission[];
        processedAt: number;
    } | null>(null);
    const { showLoading, hideLoading, setAlert, getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();

    const handleProcessedAtClick = (processedAt: number) => {
        const list = data?.commissions.filter((commission) => commission.processedAt === processedAt);
        setBatchDialogData({ commissions: list, processedAt });
    };

    const handleBatchDialogClose = () => {
        setBatchDialogData(null);
    };

    const handleCreateCommissionsConfirmed = () => {
        getConfirmation(
            async () => {
                try {
                    showLoading();
                    const objects = batchDialogData?.commissions.map((commission) => ({
                        amount: commission.amount,
                        commissionType: commission.commissionType,
                        feeId: commission.feeId,
                        processedAt: Date.now(),
                        userId: commission.user.id
                    }));
                    await createCommissions({ variables: { objects } });
                    await refetch();
                    setSnackbar('Commissions created');
                } catch {
                    setAlert('Error', 'Error occurred while creating commissions');
                } finally {
                    hideLoading();
                }
                setBatchDialogData(null);
            },
            'Make sure these commissions are paid in Gusto before recording them here',
            'Please confirm'
        );
    };

    const handleCreateCommissionsClick = () => {
        const readyCommissions = data?.commissions.filter((commission) => commission.status === 'ready_to_pay');
        if (readyCommissions.length === 0) {
            setAlert('Error', 'No commissions are ready to be paid');
            return;
        }
        setBatchDialogData({ commissions: readyCommissions, processedAt: 0 });
    };

    const batchDialog = batchDialogData ? (
        <CommissionsBatchDialog
            commissions={batchDialogData.commissions}
            processedAt={batchDialogData.processedAt}
            open={!!batchDialogData}
            onClose={handleBatchDialogClose}
            onCreate={batchDialogData.processedAt ? undefined : handleCreateCommissionsConfirmed}
        />
    ) : null;

    const userColumn: Column[] = accounting ? ['user'] : [];
    const columns: Column[] = [
        ...userColumn,
        'amount',
        'incurredAt',
        'dueAt',
        'status',
        'commissionType',
        'processedAt',
        'client',
        'candidate'
    ];

    const createCommissionsButton =
        accounting && hasRole(userPermissions, 'payroll_admin') ? (
            <Button onClick={handleCreateCommissionsClick}>Create Commissions</Button>
        ) : null;

    const navigationSelect = accounting ? <NavigationSelect routes={accountingPageRoutes} variant="outlined" /> : null;

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div>{createCommissionsButton}</div>
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    {navigationSelect}
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                </div>
            </div>
            <CommissionsDataPanel
                columns={columns}
                commissions={data?.commissions}
                searchText={searchText}
                onProcessedAtClick={handleProcessedAtClick}
            />
            {batchDialog}
        </div>
    );
};
