import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CandidateScoreEvals } from '../components/candidate-score-evals';
import { CandidateTrends } from '../components/candidate-trends';
import { Candidates } from '../components/candidates';
import { EmailAccountsStats } from '../components/email-accounts-stats';
import { Leaderboard } from '../components/leaderboard';
import { Parachute } from '../components/parachute';
import { RecruiterStats } from '../components/recruiter-stats';
import { config } from '../config';
import { Account } from '../containers/account';
import { AccountingBankTransactionsPage } from '../pages/accounting-bank-transactions-page';
import { AccountingCommissionsPage } from '../pages/accounting-commissions-page';
import { AccountingInvoicesPage } from '../pages/accounting-invoices-page';
import { AccountingRevenuePage } from '../pages/accounting-revenue-page';
import { AllUsersPage } from '../pages/all-users-page';
import { AllocationsPage } from '../pages/allocations-page';
import { AMActiveCandidatesPage } from '../pages/am-active-candidates';
import { AnalyticsPage } from '../pages/analytics';
import { AnalyticsReportPage } from '../pages/analytics-report';
import { BlacklistedCandidatesPage } from '../pages/blacklisted-candidates';
import { CandidatePage } from '../pages/candidate-page';
import { CandidatesSearchPage } from '../pages/candidates-search';
import { ClientCandidatesPage } from '../pages/client-candidates';
import { ClientInvoicesFeesPage } from '../pages/client-invoices-fees';
import { ClientMeetingsPage } from '../pages/client-meetings';
import { ClientMetricsPage } from '../pages/client-metrics';
import { ClientNewPage } from '../pages/client-new-page';
import { ClientNotesPage } from '../pages/client-notes';
import { ClientSettingsPage } from '../pages/client-settings';
import { ClientsPage } from '../pages/clients';
import { CommissionsPage } from '../pages/commissions-page';
import { CompanyTalentPage } from '../pages/company-talent';
import { EmailAccountsDashboardPage } from '../pages/email-accounts-dashboard';
import { EmailInboxPage } from '../pages/email-inbox';
import { EmailTemplates } from '../pages/email-templates';
import { FeesPage } from '../pages/fees-page';
import { FlaggedEmailsPage } from '../pages/flagged-emails';
import { HomePage } from '../pages/home';
import { Impersonate } from '../pages/impersonate';
import { InvoicesPage } from '../pages/invoices-page';
import { JobBoardPage } from '../pages/job-board';
import { JobEditPage } from '../pages/job-edit';
import { JobMeetingsPage } from '../pages/job-meetings';
import { JobMetricsPage } from '../pages/job-metrics';
import { NewJobPage } from '../pages/job-new';
import { JobNotesPage } from '../pages/job-notes';
import { JobSearchPage } from '../pages/job-search';
import { JobSearchesPage } from '../pages/job-searches';
import { JobsDetailsPage } from '../pages/jobs-details';
import { JobsMetricsPage } from '../pages/jobs-metrics';
import { LinkedInSearchesPage } from '../pages/linkedin-searches-page';
import { MetricsDashboardPage } from '../pages/metrics-dashboard-page';
import { MonthlyFeesPage } from '../pages/monthly-fees-page';
import { OutreachesPage } from '../pages/outreaches';
import { AllOutreachesPage } from '../pages/outreaches-all';
import { PersonPage } from '../pages/person';
import { PersonJobLabelPage } from '../pages/person-job-label';
import { PlacementsPage } from '../pages/placements-page';
import { ProfileRatings } from '../pages/profile-ratings';
import { ProfileUpdatesNeededPage } from '../pages/profile-updates-needed';
import { ProfilesConflictPage } from '../pages/profiles-conflict';
import { RecruiterActiveCandidatesPage } from '../pages/recruiter-active-candidates';
import { ScrumUsersPage } from '../pages/scrum-users-page';
import { SourcerSearchPage } from '../pages/sourcer-search';
import { SourcerSearchNewPage } from '../pages/sourcer-search-new';
import { SourcerSearchesPage } from '../pages/sourcer-searches';
import { TeamMetricsPage } from '../pages/team-metrics-page';
import { UserDashboardPage } from '../pages/user-dashboard';
import { UserFavoritesPage } from '../pages/user-favorites-page';
import { UserMetricsPage } from '../pages/user-metrics';
import { WeeklyUpdate } from '../pages/weekly-update';
import { Palette } from './palette';

const PaletteRoute = config.Env === 'development' ? <Route path="/palette" exact={true} component={Palette} /> : null;

export const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact={true} component={HomePage} />
            <Route path="/stats/business" exact={true} component={MetricsDashboardPage} />
            <Route path="/account" exact={true} component={Account} />
            <Route path="/user/:username/dashboard" exact={true} component={UserDashboardPage} />
            <Route path="/candidates/search" exact={true} component={CandidatesSearchPage} />
            <Route path="/candidates/placements" exact={true} component={Candidates} />
            <Route path="/placements" exact={true} component={PlacementsPage} />
            <Route path="/monthly-fees" exact={true} component={MonthlyFeesPage} />
            <Route path="/candidates/offers" exact={true} component={Candidates} />
            <Route path="/candidates/final-rounds" exact={true} component={Candidates} />
            <Route path="/candidates/second-rounds" exact={true} component={Candidates} />
            <Route path="/candidates/first-rounds" exact={true} component={Candidates} />
            <Route path="/candidates/trends" exact={true} component={CandidateTrends} />
            <Route path="/candidates/scoring" exact={true} component={CandidateScoreEvals} />
            <Route path="/stats/leaderboard" exact={true} component={Leaderboard} />
            <Route path="/invoices" exact={true} component={InvoicesPage} />
            <Route path="/fees" exact={true} component={FeesPage} />
            <Route path="/client/:id/candidates" component={ClientCandidatesPage} exact={true} />
            <Route path="/client/:id/metrics" component={ClientMetricsPage} exact={true} />
            <Route path="/client/:id/meetings" component={ClientMeetingsPage} exact={true} />
            <Route path="/client/:id/notes" component={ClientNotesPage} exact={true} />
            <Route path="/client/:id/edit" component={ClientSettingsPage} exact={true} />
            <Route path="/client/:id/invoices" component={ClientInvoicesFeesPage} exact={true} />
            <Route path="/client/:id/fees" component={ClientInvoicesFeesPage} exact={true} />
            <Route path="/client/new" component={ClientNewPage} exact={true} />
            <Route path="/recruiter/:username/candidates" component={RecruiterActiveCandidatesPage} exact={true} />
            <Route path="/allocations/:view" component={AllocationsPage} exact={true} />
            <Route path="/account-manager/:username/candidates" component={AMActiveCandidatesPage} exact={true} />
            <Route path="/stats/recruiting" component={RecruiterStats} />
            <Route path="/stats/emails" component={EmailAccountsStats} />
            <Route path="/profiles/pending/page/:page" component={ProfileUpdatesNeededPage} />
            <Route path="/profiles/pending" component={ProfileUpdatesNeededPage} exact={true} />
            <Route path="/client/:clientId/weekly-update/:all?" exact={true} component={WeeklyUpdate} />
            <Route path="/jobs" exact={true} component={JobsDetailsPage} />
            <Route path="/jobs/details" exact={true} component={JobsDetailsPage} />
            <Route path="/jobs/funnel" exact={true} component={JobsMetricsPage} />
            <Route path="/clients" exact={true} component={ClientsPage} />
            <Route path="/job/:jobId/board" exact={true} component={JobBoardPage} />
            <Route path="/job/:jobId/board/candidate/:personId" exact={true} component={CandidatePage} />
            <Route path="/job/:id/edit" exact={true} component={JobEditPage} />
            <Route path="/job/:id/meetings" exact={true} component={JobMeetingsPage} />
            <Route path="/job/:id/notes" exact={true} component={JobNotesPage} />
            <Route path="/job/:id/metrics" exact={true} component={JobMetricsPage} />
            <Route path="/job/:id/searches" exact={true} component={JobSearchesPage} />
            <Route path="/job/new" exact={true} component={NewJobPage} />
            <Route path="/person/:personId/job/:jobId/label" exact={true} component={PersonJobLabelPage} />
            <Route path="/searches/linkedin" exact={true} component={LinkedInSearchesPage} />
            <Route path="/searches/linkedin/:searchId" exact={true} component={LinkedInSearchesPage} />
            <Route path="/person/:id" exact={true} component={PersonPage} />
            <Route path="/person/:id" exact={true} component={PersonPage} />
            <Route path="/analytics" exact={true} component={AnalyticsPage} />
            <Route path="/analytics/:report" exact={true} component={AnalyticsReportPage} />
            <Route path="/outreaches" component={AllOutreachesPage} exact={true} />
            <Route path="/company/talent" component={CompanyTalentPage} />
            <Route path="/user/:username/outreaches" component={OutreachesPage} exact={true} />
            <Route path="/user/:username/metrics" component={UserMetricsPage} exact={true} />
            <Route path="/emails/flagged" component={FlaggedEmailsPage} exact={true} />
            <Route path="/emails/:account?" component={EmailInboxPage} />
            <Route path="/email-accounts" exact={true} component={EmailAccountsDashboardPage} />
            <Route path="/users" exact={true} component={AllUsersPage} />
            <Route path="/users/scrum" exact={true} component={ScrumUsersPage} />
            <Route path="/templates" exact={true} component={EmailTemplates} />
            <Route path="/impersonate/:email" component={Impersonate} />
            <Route
                path="/job/:jobId/search/:searchId?/:resultType?/:personId?"
                exact={true}
                component={JobSearchPage}
            />
            <Route path="/candidates/blacklist" component={BlacklistedCandidatesPage} />
            <Route path="/candidates/favorites" component={UserFavoritesPage} />
            {PaletteRoute}
            <Route path="/parachute" component={Parachute} exact={true} />
            <Route path="/profile/ratings" component={ProfileRatings} exact={true} />
            <Route path="/sourcer/:id/searches" exact={true} component={SourcerSearchesPage} />
            <Route path="/sourcer/:sourcerId/search/:searchId/:viewType" exact={true} component={SourcerSearchPage} />
            <Route path="/sourcer/:sourcerId/search" exact={true} component={SourcerSearchNewPage} />
            <Route path="/team/:id/metrics" exact={true} component={TeamMetricsPage} />
            <Route path="/profile-conflicts" component={ProfilesConflictPage} />
            <Route path="/accounting/invoices" exact={true} component={AccountingInvoicesPage} />
            <Route path="/accounting/revenue" exact={true} component={AccountingRevenuePage} />
            <Route path="/accounting/bank-transactions" exact={true} component={AccountingBankTransactionsPage} />
            <Route path="/accounting/commissions" exact={true} component={AccountingCommissionsPage} />
            <Route path="/commissions" exact={true} component={CommissionsPage} />
        </Switch>
    );
};
