import { useMutation } from '@apollo/client';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { useState } from 'react';
import { hasJobPermission } from 'shared/models/job';

import { JobsListDialog } from '../containers/jobs-list-dialog';
import { CREATE_PROFILE_SCORE_REQUEST } from '../graphql/queries/profile-score';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { Job } from '../state';

export const ProfileScoringRequestButton: React.FC<{ personId: string }> = ({ personId }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [jobSelectorOpen, setJobSelectorOpen] = useState(false);
    const [createProfileScoreRequest] = useMutation<
        {},
        { objects: Array<{ personId: string; jobId: string | null; completedAt: string | null }> }
    >(CREATE_PROFILE_SCORE_REQUEST);
    const { showLoading, hideLoading, setAlert } = useModal();
    const { setSnackbar } = useSnackbar();
    const { user } = useSession();

    const handleCreateProfileScoreRequest = async (jobIds: string[] | null) => {
        showLoading();
        try {
            const objects = jobIds
                ? jobIds.map((jobId) => ({ personId, jobId, completedAt: null }))
                : [{ personId, jobId: null, completedAt: null }];
            await createProfileScoreRequest({
                variables: {
                    objects
                }
            });
            setSnackbar('Profile scoring request created successfully');
        } catch (error) {
            setAlert('Error', 'Failed to create profile scoring request');
        } finally {
            hideLoading();
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAllActiveJobs = async () => {
        handleClose();
        await handleCreateProfileScoreRequest(null);
    };

    const handleCloseJobSelector = () => {
        setJobSelectorOpen(false);
    };

    const handleSubmitToJobSelected = (jobIds: string[]) => {
        setJobSelectorOpen(false);
        handleClose();
        handleCreateProfileScoreRequest(jobIds);
    };

    const handlePickJob = () => {
        handleClose();
        setJobSelectorOpen(true);
    };

    const jobsFilter = (j: Job) => {
        return hasJobPermission(j.recruiterPermissions.sendOutreach, user.id);
    };

    const jobSelectorDialog = jobSelectorOpen ? (
        <JobsListDialog
            sourceJobId={null}
            onRequestClose={handleCloseJobSelector}
            onJobSelect={handleSubmitToJobSelected}
            jobsFilter={jobsFilter}
            multiple={true}
            confirmLabel="Confirm Jobs"
        />
    ) : null;

    return (
        <>
            <Tooltip title="Generate Score">
                <IconButton size="small" onClick={handleClick}>
                    <AddCircle fontSize="small" htmlColor="white" />
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleAllActiveJobs}>All Active Jobs</MenuItem>
                <MenuItem onClick={handlePickJob}>Pick Jobs</MenuItem>
            </Menu>
            {jobSelectorDialog}
        </>
    );
};
