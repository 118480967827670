import { css } from '@emotion/core';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Assignment, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';

import { jdUpdateContextDescription } from 'shared/models/jd-llm-updates';
import { hasRole } from 'shared/models/user';
import { recordJDUpdateRating } from '../../api';

import { interviewTime } from '../../common/timestamp';
import { JDLLMUpdate as JDLLMUpdateData } from '../../graphql/queries/notifications';
import { useSession } from '../../hooks/use-session';
import { JDLLMUpdateDetails } from '../jd-llm-update-details';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }

    .item-text {
        border-left: 2px solid ${theme.palette.divider};
        padding-left: 8px;
        margin-top: 4px;
        font-style: italic;
    }
`;

export const JDLLMUpdate: React.FC<{ data: JDLLMUpdateData; userId: string; onComplete: () => void }> = ({
    data,
    userId,
    onComplete
}) => {
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const theme = useTheme();
    const session = useSession();

    const handleToggleDetails = () => {
        setDetailsOpen(!detailsOpen);
    };

    const handleCreateRating = (rating: boolean, comment?: string) => {
        onComplete();
        if (data.inferenceId) {
            recordJDUpdateRating(data.inferenceId, rating, comment);
        }
    };

    const { job, ...rest } = data;

    const detailsDialog = !detailsOpen ? null : (
        <JDLLMUpdateDetails
            updateData={rest}
            job={job}
            open={true}
            onClose={handleToggleDetails}
            onCreateRating={handleCreateRating}
        />
    );

    const primary = (
        <Typography variant="h6">
            {data.job.client.name} - {data.job.title}
        </Typography>
    );
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            Job description updated by Titan using
            <br />
            <i>{jdUpdateContextDescription(data.context)}</i>
        </Typography>
    );
    const actions =
        session.user.id === userId && hasRole(session.userPermissions, 'prompts_editor') ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={onComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    return (
        <>
            <ListItem css={styles(theme)} onClick={handleToggleDetails} button={true}>
                <ListItemAvatar>
                    <Avatar>
                        <Assignment />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
                <div>
                    <Typography
                        color="textSecondary"
                        noWrap={true}
                        variant="caption"
                        className="caption"
                        component="div"
                    >
                        <span className="left-pad">{interviewTime(data.updatedAt)}</span>
                    </Typography>
                </div>
                {actions}
            </ListItem>
            {detailsDialog}
        </>
    );
};
