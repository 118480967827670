import { useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import { Avatar, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import { AssignmentOutlined, AssignmentTurnedInOutlined, AttachMoney, Phone, Send } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

import { internalClientIds, sourcerJobTypes } from 'shared/models/client';

import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    hiredStage,
    offerStage,
    rocketScreenCompleteStage
} from 'shared/models/job-stages';
import { getStatsMap, Stat } from '../../common/recruiter-stats';
import {
    Billing,
    BILLINGS,
    BillingVariables,
    FunnelStats,
    HOMEPAGE_ACCEPTS_TARGET,
    HOMEPAGE_FUNNEL_BASELINE_STATS_SUB,
    HOMEPAGE_FUNNEL_STATS_SUB
} from '../../graphql/queries/recruiter-stats';
import { usePeriodFunnelCandidates } from '../../hooks/use-period-funnel-candidates';
import { useUserContext } from '../../hooks/use-user-context';
import { FunnelStat } from '../funnel-stat';

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    display: flex;
    margin-bottom: 25px;

    .section {
        padding: 20px 24px 24px;
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &:not(:last-of-type) {
            border-right: thin solid ${theme.palette.divider};
        }

        .clickable {
            cursor: pointer;
        }

        &.off {
            border: none;
            display: none;
        }

        .section-icon {
            margin-left: 25px;

            i.fas {
                font-size: 14px;
            }
        }

        .MuiAvatar-root {
            width: 40px;
            height: 40px;
            background-color: ${theme.palette.primary.main};

            .MuiSvgIcon-root {
                color: white;
                font-size: 20px;
            }
        }

        .funnel-stat {
            .stat-main {
                font-size: 30px;
                font-weight: 500;
                line-height: 1.2;
                letter-spacing: -0.24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: ${theme.shape.borderRadius}px;

                &.error {
                    padding: 0 10px;
                    background-color: ${theme.palette.error.main};
                    color: ${theme.palette.error.contrastText};
                }

                &.warning {
                    padding: 0 10px;
                    background-color: ${theme.palette.warning.light};
                    color: ${theme.palette.warning.contrastText};
                }

                &.success {
                    padding: 0 10px;
                    background-color: ${theme.palette.success.light};
                    color: ${theme.palette.success.contrastText};
                }
            }
        }

        &.primary {
            background-color: ${theme.palette.primary.main};
            color: white;
            border-top-right-radius: ${theme.shape.borderRadius}px;
            border-bottom-right-radius: ${theme.shape.borderRadius}px;

            .MuiAvatar-root {
                background-color: white;

                .MuiSvgIcon-root {
                    color: ${theme.palette.primary.main};
                }
            }
        }
    }
`;
// tslint:enable: no-magic-numbers

const toggleViewIntervalMs = 30000; // 30 seconds
const billingDataPollIntervalMs = 300000; // 5 minutes

export const HomeStats: React.FC<{}> = () => {
    const theme = useTheme();
    const [billingPeriodStart] = React.useState(moment().startOf('quarter').valueOf());
    const [billingPeriodEnd] = React.useState(moment().endOf('quarter').valueOf());
    const [secondaryPanelsView, setSecondaryPanelsView] = React.useState(false);
    const [panelsFlag, setPanelsFlag] = React.useState(false);
    const [panelHovered, setPanelHovered] = React.useState(false);
    const periodDays = 14;
    const currentPeriod = `last ${periodDays} days`;
    const defaultBaselinePeriod = `previous ${periodDays} days`;

    const { user } = useUserContext();
    const { setPeriodFunnelCandidatesData } = usePeriodFunnelCandidates();

    const { data: billingData } = useQuery<Billing, BillingVariables>(BILLINGS, {
        pollInterval: billingDataPollIntervalMs,
        variables: {
            assignee: user.id,
            endTime: billingPeriodEnd,
            excludeClientIds: internalClientIds,
            excludeJobTypes: sourcerJobTypes,
            startTime: billingPeriodStart
        }
    });
    const { data: funnelData } = useSubscription<FunnelStats>(HOMEPAGE_FUNNEL_STATS_SUB);
    const { data: funnelBaselineData } = useSubscription<FunnelStats>(HOMEPAGE_FUNNEL_BASELINE_STATS_SUB);
    const { data: targetAcceptsData } = useQuery<{ target: Array<{ goal: number }> }>(HOMEPAGE_ACCEPTS_TARGET, {
        variables: { username: user.username }
    });

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (!panelHovered) {
                setSecondaryPanelsView(!secondaryPanelsView);
            }
            setPanelsFlag(!panelsFlag);
        }, toggleViewIntervalMs);
        return () => {
            clearTimeout(timer);
        };
    }, [panelsFlag, panelHovered]);

    const handlePanelMouseOver = () => setPanelHovered(true);
    const handlePanelMouseLeave = () => setPanelHovered(false);
    const handleClick = () => setSecondaryPanelsView(!secondaryPanelsView);

    const handlePanelClick = (label: string, stage: string, stat: Stat) => () => {
        if (stat?.value) {
            const userVal = { id: user.id, name: user.name.full };
            const end = moment().endOf('day').valueOf();
            const start = moment().subtract(periodDays, 'days').startOf('day').valueOf();
            setPeriodFunnelCandidatesData({ label, period: { start, end }, user: userVal, stage });
        }
    };

    const handleBillingClick = (e: React.MouseEvent) => {
        if (
            billingData?.recruiterBillings?.aggregate?.sum?.commission ||
            billingData?.amBillings?.aggregate?.sum?.commission
        ) {
            e.stopPropagation();
            const userVal = { id: user.id, name: user.name.full };
            setPeriodFunnelCandidatesData({
                label: 'Billings',
                period: { start: billingPeriodStart, end: billingPeriodEnd },
                stage: hiredStage,
                user: userVal
            });
        }
    };

    const { dataMap: stats } = getStatsMap(funnelData, funnelBaselineData);
    const userStats = stats.get(user.id);

    const panelsData = [
        {
            baselinePeriod: 'yesterday',
            data: userStats?.outreachesSent,
            display: !secondaryPanelsView,
            formatter: (value: number) => value.toLocaleString(),
            header: 'Outreach Today',
            icon: <Send />,
            period: 'the day',
            tooltip: `outreach emails sent`
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            clickHandler: handlePanelClick('Phone Calls', rocketScreenCompleteStage, userStats?.phoneScreens),
            data: userStats?.phoneScreens,
            display: !secondaryPanelsView,
            header: 'Phone Calls',
            icon: <Phone />,
            period: currentPeriod,
            tooltip: 'phone calls'
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            clickHandler: handlePanelClick('Submissions', awaitingClientStage, userStats?.submits),
            data: userStats?.submits,
            display: !secondaryPanelsView,
            header: 'Submissions',
            icon: <AssignmentOutlined />,
            period: currentPeriod,
            tooltip: `submissions sent`
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            clickHandler: handlePanelClick('Client Accepts', clientFirstRoundStage, userStats?.clientAccepts),
            data: userStats?.clientAccepts,
            display: !secondaryPanelsView,
            header: 'Client Accepts',
            icon: <AssignmentTurnedInOutlined />,
            period: currentPeriod,
            target: targetAcceptsData?.target[0]?.goal,
            tooltip: `candidates accepted by clients (target ${targetAcceptsData?.target[0]?.goal})`
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            data: userStats?.callAcceptRatio,
            display: secondaryPanelsView,
            header: 'Accepts/Calls Ratio',
            icon: <i className="fas fa-percentage" />,
            period: currentPeriod,
            tooltip: `client accepts per phone call`
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            clickHandler: handlePanelClick('Final Rounds', clientFinalRoundStage, userStats?.finalRounds),
            data: userStats?.finalRounds,
            display: secondaryPanelsView,
            header: `Final Rounds`,
            icon: <i className="fas fa-user-tie" />,
            period: currentPeriod,
            tooltip: `client final rounds`
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            clickHandler: handlePanelClick('Offers', offerStage, userStats?.offers),
            data: userStats?.offers,
            display: secondaryPanelsView,
            header: `Offers`,
            icon: <i className="fas fa-file-invoice-dollar" />,
            period: currentPeriod,
            tooltip: `offers`
        },
        {
            baselinePeriod: defaultBaselinePeriod,
            clickHandler: handlePanelClick('Placements', hiredStage, userStats?.hired),
            data: userStats?.hired,
            display: secondaryPanelsView,
            header: `Placements`,
            icon: <i className="fas fa-dollar-sign" />,
            period: currentPeriod,
            tooltip: `placements`
        }
    ];

    const panels = panelsData.map(
        ({ header, icon, data, tooltip, period, baselinePeriod, display, formatter, target, clickHandler }) => (
            <div key={header} className={`section ${display ? 'on' : 'off'}`}>
                <div className="section-text">
                    <Typography variant="overline" color="textSecondary" gutterBottom={true}>
                        {header}
                    </Typography>
                    <FunnelStat
                        data={data}
                        description={tooltip}
                        period={period}
                        baselinePeriod={baselinePeriod}
                        formatter={formatter}
                        target={target}
                        onClick={clickHandler}
                    />
                </div>
                <div className="section-icon">
                    <Avatar>{icon}</Avatar>
                </div>
            </div>
        )
    );

    const recruiterCommissions = billingData?.recruiterBillings.aggregate.sum.commission;
    const amCommissions = billingData?.amBillings.aggregate.sum.commission;
    const commissions = (recruiterCommissions ?? 0) + (amCommissions ?? 0);
    const billingsPanel = (
        <div className="section primary">
            <div className="section-text">
                <Typography variant="overline" gutterBottom={true}>
                    {moment().format('[Q]Q')} Commission
                </Typography>
                <div className="funnel-stat">
                    <span className="stat-main clickable" onClick={handleBillingClick}>
                        <span>{commissions ? `$${Math.round(commissions).toLocaleString()}` : '$0'}</span>
                    </span>
                </div>
            </div>
            <div className="section-icon">
                <Avatar>
                    <AttachMoney />
                </Avatar>
            </div>
        </div>
    );

    return (
        <Paper
            css={styles(theme)}
            onMouseOver={handlePanelMouseOver}
            onMouseLeave={handlePanelMouseLeave}
            onClick={handleClick}
        >
            {panels}
            {billingsPanel}
        </Paper>
    );
};
