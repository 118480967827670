import { Map } from 'immutable';

import { Candidate, Communication, EmailAccount } from '../state';

export const getLastCommunicationWithCandidate = (
    communications: Communication[],
    emailAccounts: Map<string, EmailAccount>,
    candidate: Candidate
) => {
    if (!candidate) return undefined;
    const jobId = candidate.jobId;
    const emails = communications.filter(
        (c) =>
            (c.jobIds || []).includes(jobId) &&
            !c.isClientComm &&
            c.category === 'candidate' &&
            // remove filtering accounts by candidate assignee ownership - better handling in case of assignee change
            // emailAccounts.get(c.account).owners.includes(candidate.assignee) &&
            (emailAccounts.get(c.account)?.jobId === jobId || !emailAccounts.get(c.account)?.jobId) &&
            c.sender !== 'notifications@mixmax.com' &&
            !c.matchData.error &&
            !c.matchData.ignored
    );
    const lastEmail = emails.sort((c1, c2) => c2.internalDate - c1.internalDate)[0];
    return lastEmail;
};
