import { css } from '@emotion/core';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { orderBy, sortBy } from 'lodash';
import * as React from 'react';

import { TableColumnFilterHeader } from './table-column-filter-header';

export const selectorStyles = css`
    > .MuiInputBase-root {
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }

        .MuiOutlinedInput-input {
            padding-top: 14px;
            padding-bottom: 14px;
            background: white;
        }

        background: white;
        margin: 0 0 0 15px;

        .MuiOutlinedInput-input {
            padding-top: 9px;
            padding-bottom: 9px;
            background: white;
        }
    }
`;

export const dataPanelContainerStyles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        flex: 0 0 auto;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 20px;

        &:has(> *:only-child) {
            justify-content: flex-end; /* When only one child */
        }

        .search.outlined {
            display: inline-flex;

            .MuiInputBase-input {
                padding: 8px 8px 8px 40px;
            }
        }
    }

    .data-panel {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;
    }
`;

const dataPanelStyles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .MuiTableContainer-root {
        flex: 1 1 auto;
        overflow: auto;
    }

    .table-header-cell {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    .column-action-icon {
        display: inline-flex;
        align-items: center;
        opacity: 0;
        transition: opacity 200ms;
        margin-left: 5px;

        .MuiSvgIcon-root {
            font-size: 1.25rem;
            color: ${theme.palette.text.secondary};
        }

        &.visible {
            opacity: 1;
        }
    }

    th:hover {
        .column-action-icon {
            opacity: 1;
        }
    }

    .MuiTableBody-root {
        .MuiTableRow-root {
            &:last-child {
                .MuiTableCell-root {
                    border-bottom: none;
                }
            }

            &:hover {
                background: ${theme.palette.action.hover};
            }

            .clickable-cell {
                cursor: pointer;
                color: ${theme.palette.primary.main};
            }
        }
    }

    .table-footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        border-top: thin solid ${theme.palette.divider};

        .summary {
            margin-left: 15px;
            font-size: 14px;
        }
    }
`;

export const dataPanelDialogStyles = (theme: Theme) => css`
    .MuiDialogContent-root {
        padding: 0;
        border-top: thin solid ${theme.palette.divider};
        border-bottom: thin solid ${theme.palette.divider};
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .data-panel {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
        }
    }

    .MuiTableCell-root {
        &:first-child {
            padding-left: 28px;
        }

        &:last-child {
            padding-right: 28px;
        }
    }

    .table-footer {
        .summary {
            margin-left: 28px;
        }

        .MuiTablePagination-root {
            margin-right: 20px;
        }
    }
`;

export interface DataPanelColumn<T extends string> {
    id: T;
    label: string | JSX.Element;
    width?: number;
}

export type CellValue = string | number | Date | boolean | null;

type CellRenderer<T extends string, R> = {
    [K in T]?: (value: CellValue, row: R) => string | JSX.Element;
};

type CellFilterValue<T extends string, R> = {
    [K in T]?: (value: CellValue, row: R) => CellValue;
};

const defaultPageSize = 15;

interface DataPanelProps<T extends string, R> {
    columns: Array<DataPanelColumn<T>>;
    data: R[];
    getCellValue: (column: T) => (row: R) => CellValue;
    getTotalValue?: (column: T) => CellValue | JSX.Element;
    getCellRenderer?: CellRenderer<T, R>;
    getCellFilterValue?: CellFilterValue<T, R>;
    initialSortColumn?: T;
    initialSortDirection?: 'asc' | 'desc';
    pageSize?: number;
    hidePagination?: boolean;
    sortableColumns?: T[];
    filterableColumns?: T[];
    className?: string;
    renderSummary?: (filteredData: R[]) => JSX.Element;
    rowFilter?: (row: R) => boolean;
}

export function DataPanel<T extends string, R>({
    columns,
    data,
    getCellValue,
    getCellRenderer = {},
    getCellFilterValue = {},
    getTotalValue,
    hidePagination = false,
    pageSize = defaultPageSize,
    initialSortColumn,
    initialSortDirection,
    sortableColumns = [],
    filterableColumns = [],
    className,
    renderSummary,
    rowFilter
}: DataPanelProps<T, R>): JSX.Element {
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState<T | null>(initialSortColumn || null);
    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(initialSortDirection || 'asc');
    const [filters, setFilters] = React.useState<Map<T, Set<CellValue>>>(new Map());

    const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);

    const handleSort = (column: T) => () => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handleFilter = (column: T) => (selected: CellValue[]) => {
        setFilters((prevFilters) => {
            const newFilters = new Map(prevFilters);
            if (selected.length === 0) {
                newFilters.delete(column);
            } else {
                newFilters.set(column, new Set(selected));
            }
            return newFilters;
        });
        setPage(0);
    };

    const filteredData = React.useMemo(() => {
        return data?.filter(
            (row) =>
                (!rowFilter || rowFilter(row)) &&
                Array.from(filters.entries()).every(([column, values]) => {
                    const cellValue = getCellValue(column)(row);
                    const filterTransformer = getCellFilterValue[column];
                    const valueToCheck = filterTransformer ? filterTransformer(cellValue, row) : cellValue;
                    return values.has(valueToCheck);
                })
        );
    }, [data, filters, getCellValue, getCellFilterValue, rowFilter]);

    const sortedData = React.useMemo(() => {
        if (!sortColumn) return filteredData;
        return orderBy(filteredData, [getCellValue(sortColumn)], [sortDirection]);
    }, [filteredData, sortColumn, sortDirection, getCellValue]);

    const paginatedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize);

    const headerCells = columns.map(({ id, label }) => {
        const isSortable = sortableColumns.includes(id);
        const isFilterable = filterableColumns.includes(id);
        const sortIcon = sortDirection === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
        const sortIconElement = isSortable && (
            <span className={`column-action-icon ${sortColumn === id ? 'visible' : ''}`}>{sortIcon}</span>
        );
        let filterElement: JSX.Element | null = null;
        if (isFilterable) {
            const filterValues =
                data?.map((row) => {
                    const value = getCellValue(id)(row);
                    const filterTransformer = getCellFilterValue[id];
                    const filterValue = filterTransformer ? filterTransformer(value, row) : value;
                    return { filterValue, value };
                }) ?? [];
            const sortedFilterValues = sortBy(filterValues, 'value').map(({ filterValue }) => filterValue);
            filterElement = (
                <TableColumnFilterHeader
                    values={sortedFilterValues}
                    selected={Array.from(filters.get(id) || [])}
                    onSelect={handleFilter(id)}
                />
            );
        }

        return (
            <TableCell key={id}>
                <span className="table-header-cell">
                    <span onClick={isSortable ? handleSort(id) : undefined}>{label}</span>
                    {sortIconElement}
                    {filterElement}
                </span>
            </TableCell>
        );
    });

    const rows = paginatedData?.map((row, rowIndex) => {
        const cells = columns.map(({ id }) => {
            const value = getCellValue(id)(row);
            const renderer = getCellRenderer[id];
            const displayValue = renderer ? renderer(value, row) : String(value);
            return <TableCell key={id}>{displayValue}</TableCell>;
        });

        return <TableRow key={rowIndex}>{cells}</TableRow>;
    });

    const pagination = (
        <TablePagination
            rowsPerPageOptions={[pageSize]}
            component="div"
            count={sortedData?.length}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={handleChangePage}
        />
    );

    const summary = React.useMemo(
        () => (renderSummary ? renderSummary(sortedData) : null),
        [renderSummary, sortedData]
    );

    const footer =
        !data || (hidePagination && data.length < pageSize) ? null : (
            <div className="table-footer">
                {pagination}
                <div className="summary">{summary}</div>
            </div>
        );

    const skeletonRows = !data
        ? Array.from({ length: Math.min(pageSize, defaultPageSize) }).map((_, index) => (
              <TableRow key={index}>
                  <TableCell colSpan={columns.length}>
                      <Skeleton variant="text" />
                  </TableCell>
              </TableRow>
          ))
        : [];

    let totalsRow: JSX.Element | null = null;
    if (getTotalValue && data?.length > 0) {
        const totalCells = columns.map(({ id }) => {
            const value = getTotalValue(id);
            return <TableCell key={id}>{value}</TableCell>;
        });
        totalsRow = <TableRow className="totals-row">{totalCells}</TableRow>;
    }

    return (
        <div css={dataPanelStyles(theme)} className={className}>
            <TableContainer>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headerCells}</TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                        {skeletonRows}
                        {totalsRow}
                    </TableBody>
                </Table>
            </TableContainer>
            {footer}
        </div>
    );
}
