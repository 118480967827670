import { gql } from '@apollo/client';

export interface SearchScore {
    id: string;
    searchId: string;
    title: string;
    companyInformation: string;
    jobDescription: string;
    additionalContext: string;
    user: {
        id: string;
        name: string;
    };
    startedAt: number;
    completedAt: number;
    createdAt: number;
}

export interface JobSearchScoring {
    id: string;
    title: string;
    user: {
        id: string;
        name: string;
    };
    createdAt: number;
    companyInformation: string;
    jobDescription: string;
    additionalContext: string;
}

export interface ProfileScore {
    id: string;
    personId: string;
    score: number;
    jobId: string;
    generationInfo: {
        resultsFile?: string;
        episodeId?: string;
        inferenceId?: string;
    };
    scoreExplanation: string;
}

export interface SearchResultScore extends ProfileScore {
    searchId: string;
    searchProfilesScoreId: string;
}

export interface CandidateScore extends ProfileScore {
    user?: {
        id: string;
        name: string;
    };
    createdAt: number;
    searchId: string;
}

export interface PersonJobScore {
    id: string;
    score: number;
    generationInfo: {
        resultsFile?: string;
        episodeId?: string;
        inferenceId?: string;
    };
    scoreExplanation: string;
    createdAt: number;
    personId: string;
    jobId: string;
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    user: {
        id: string;
        name: string;
    };
    searchId: string;
}

export const SEARCH_PROFILE_SCORES = gql`
    query SearchProfileScores($searchId: String!) {
        scores: search_profiles_scores(where: { searchId: { _eq: $searchId } }, order_by: { createdAt: desc }) {
            id
            searchId
            title
            companyInformation
            jobDescription
            additionalContext
            startedAt
            completedAt
            createdAt
            user {
                id
                name(path: "full")
            }
        }
    }
`;

export const JOB_SEARCH_PROFILE_SCORES = gql`
    query JobSearchProfilesScores($jobId: String) {
        jobSearchScorings: search_profiles_scores(where: { search: { jobId: { _eq: $jobId } } }) {
            id
            title
            user {
                id
                name(path: "full")
            }
            createdAt
            companyInformation
            jobDescription
            additionalContext
        }
    }
`;

export const CREATE_SEARCH_PROFILE_SCORE = gql`
    mutation CreateSearchProfileScore($object: search_profiles_scores_insert_input!) {
        score: insert_search_profiles_scores_one(object: $object) {
            id
        }
    }
`;

export const SEARCH_PROFILE_SCORE_VALUES = gql`
    query ProfileScores($scoreId: String!) {
        scores: profile_scores(where: { searchProfilesScoreId: { _eq: $scoreId } }) {
            personId
            score
        }
    }
`;

export const SEARCH_PROFILES_SCORE_RESULTS = gql`
    query SearchProfilesScoreResults($scoreId: String!, $personIds: [String!]!) {
        scores: profile_scores(where: { searchProfilesScoreId: { _eq: $scoreId }, personId: { _in: $personIds } }) {
            id
            personId
            jobId
            searchProfilesScoreId
            searchId
            score
            scoreExplanation
            generationInfo
        }
    }
`;

export const CANDIDATE_PROFILE_SCORES = gql`
    query CandidateProfileScore($jobId: String!, $personId: String!) {
        scores: profile_scores(
            where: { jobId: { _eq: $jobId }, personId: { _eq: $personId } }
            order_by: { createdAt: desc }
        ) {
            id
            personId
            jobId
            score
            scoreExplanation
            generationInfo
            createdAt
            searchId
            user {
                id
                name(path: "full")
            }
        }
    }
`;

export const SEARCH_RESULTS_TO_SCORE_COUNT = gql`
    query GetSearchResultsToScoreCount($searchId: String!) {
        resultsCount: get_search_results_to_score_count(args: { search_id: $searchId }) {
            result
        }
    }
`;

export const CREATE_PROFILE_SCORE = gql`
    mutation createProfileScore($personId: String!, $jobId: String!, $score: float8!) {
        score: insert_profile_scores_one(
            object: { personId: $personId, jobId: $jobId, score: $score }
            on_conflict: {
                constraint: profile_scores_person_job_user_unique
                update_columns: [score] # Columns to update if a conflict is detected
            }
        ) {
            id
        }
    }
`;

export const DELETE_PROFILE_SCORE = gql`
    mutation DeleteProfileScore($id: String!) {
        delete_profile_scores(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_PROFILE_SCORE_REQUEST = gql`
    mutation CreateProfileScoreRequest($objects: [candidate_scoring_requests_insert_input!]!) {
        insert_candidate_scoring_requests(objects: $objects) {
            affected_rows
        }
    }
`;

export const PERSON_JOB_SCORES = gql`
    query PersonJobScores($personId: String!, $minScore: float8!, $includeSubmitted: Boolean!) {
        scores: person_job_scores(
            args: { person_id: $personId, min_score: $minScore, include_submitted: $includeSubmitted }
            order_by: [{ score: desc }, { job: { createdAt: desc } }]
        ) {
            id
            score
            generationInfo
            scoreExplanation
            createdAt
            personId
            jobId
            job {
                id
                title
                client {
                    id
                    name
                }
            }
            user {
                id
                name(path: "full")
            }
            searchId
        }
    }
`;
