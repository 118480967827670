import { css } from '@emotion/core';
import { Tooltip } from '@material-ui/core';
import { flatMap } from 'lodash';
import React from 'react';

import { standardizeUrl } from 'profile-parser';
import { SearchConfigKeywordGroup } from 'shared/models/search';

import { AIProfileScore } from '../components/ai-profile-score';
import { AIProfileScoreExplanation } from '../components/ai-profile-score-explanation';
import { ProfileScore, SearchResultScore } from '../graphql/queries/profile-score';
import { ProfileRecord } from '../graphql/queries/profiles';
import { useAIProfileScoreDetails } from '../hooks/use-ai-profile-score-details';
import { useSlides } from '../hooks/use-candidate-slides';
import { AdornmentGenerator } from '../hooks/use-profile-adornment';
import { keywordSearchPresetGroups } from './search-keywords-requirements';
import { SearchResultActions } from './search-result-actions';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';

const previewScoresStyles = css`
    position: absolute;
    top: 0;
    bottom: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 120px;

    .preview-scores {
        display: flex;
        flex-direction: row-reverse;
        font-size: 40px;
        font-weight: 300;
    }

    .search-result-actions {
        justify-content: flex-end;
        margin-top: 15px;
        margin-right: 0;
    }

    .li-link {
        text-align: right;
        font-size: 28px;
        padding-right: 5px;
    }
`;

export const useSearchResultProfileAdornments = (
    profiles: ProfileRecord[],
    resultScores: SearchResultScore[],
    onSelectPerson: (personId: string) => void
) => {
    const { data: search, resultsType, getSortedResults, resultScores: resultScoreValues } = useSearch();
    const { searchPresets } = useSearchPresets();
    const { showScoreDetails } = useAIProfileScoreDetails();
    const { setList } = useSlides();

    const sortedResults = getSortedResults();

    const replacePresets = (groups: SearchConfigKeywordGroup[]) => {
        return flatMap(
            groups.map((g) =>
                flatMap(
                    g.keywords.map((kw) => {
                        const matchingPreset = searchPresets.find(
                            (p) => p.name === kw.label && keywordSearchPresetGroups.includes(p.group)
                        );
                        if (kw.isPreset && !!matchingPreset) {
                            return matchingPreset.list.map((l) => l.label || l.value);
                        } else {
                            return [kw.label];
                        }
                    })
                )
            )
        );
    };

    const handleAIScoreClick = (score: ProfileScore) => () => {
        showScoreDetails(score);
    };

    const getProfileAdornments: AdornmentGenerator = (personId: string) => {
        const profile = profiles.find((p) => p.person.id === personId);
        const result = sortedResults.find((r) => r.personId === personId);
        if (!profile || !result) {
            return {};
        }
        const resultScore = resultScores.find(
            (s) => s.personId === result.personId && s.searchProfilesScoreId === search?.searchProfilesScoreId
        );
        const resultScoreValue = resultScoreValues.get(result.personId);

        const profileLink = profile.content.publicLink ? (
            <a href={`https://${standardizeUrl(profile.content.publicLink)}`} target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin" />
            </a>
        ) : null;

        const detailProfileActions = (
            <div className="search-result-actions">
                <SearchResultActions
                    jobId={search.jobId}
                    result={result}
                    resultsType={resultsType}
                    onNavigateNext={handleNavigateNext}
                />
            </div>
        );

        let scoreName;
        let scoreValue;
        let previewScore: JSX.Element | null = null;
        let explanationPreview: JSX.Element | null = null;
        let explanationDetailedView: JSX.Element | null = null;
        if (search?.searchProfilesScoreId) {
            explanationPreview = (
                <div className="section">
                    <div className="section-icon">Explanation</div>
                    <div className="section-details" style={{ fontWeight: 500, color: 'black' }}>
                        {resultScore?.scoreExplanation}
                    </div>
                </div>
            );
            previewScore = (
                <Tooltip title="AI Evaluation Score, Click for details">
                    <div onClick={handleAIScoreClick(resultScore)} style={{ cursor: 'pointer' }}>
                        {resultScoreValue?.toFixed(1)}
                    </div>
                </Tooltip>
            );
            explanationDetailedView = <AIProfileScoreExplanation score={resultScore} />;
        } else {
            switch (search?.resultsSort) {
                case 'skillScore':
                    scoreName = 'Skill Score';
                    scoreValue = result.scores.skillScore;
                    break;
                case 'similarityScore':
                    scoreName = 'Similarity Score';
                    scoreValue = result.scores.similarityScore;
                    break;
                case 'totalScore':
                    scoreName = 'Total Score';
                    scoreValue = result.scores.totalScore;
                    break;
                default:
                    break;
            }
            previewScore =
                scoreName && scoreValue !== undefined ? (
                    <Tooltip title={scoreName}>
                        <div>{Math.round(scoreValue * 100)}</div>
                    </Tooltip>
                ) : null;
        }

        const previewScoreAndActions = (
            <div css={previewScoresStyles}>
                <div className="preview-scores-actions">
                    <div className="preview-scores">{previewScore}</div>
                    <div className="search-result-actions">
                        <SearchResultActions jobId={search.jobId} result={result} resultsType={resultsType} />
                    </div>
                </div>
                <div className="li-link">{profileLink}</div>
            </div>
        );

        const positiveTerms = search.config
            ? replacePresets(search.config.keywordGroups.filter((g) => !g.negative))
            : [];
        const negativeTerms = search.config
            ? replacePresets(search.config.keywordGroups.filter((g) => g.negative))
            : [];
        const highlightTerms = [
            { className: 'positive', terms: positiveTerms },
            { className: 'negative', terms: negativeTerms }
        ];

        const scoreDetailedView = <AIProfileScore score={resultScore} />;

        return {
            highlightTerms,
            insertElements: [
                { slot: 'score', element: previewScoreAndActions },
                { slot: 'explanation', element: explanationPreview },
                { slot: 'explanationDetailedView', element: explanationDetailedView },
                { slot: 'detailProfileActions', element: detailProfileActions },
                { slot: 'scoreDetailedView', element: scoreDetailedView }
            ]
        };
    };

    const handleNavigate = (index: number) => {
        const list = sortedResults.filter((r) => !!r?.personId);
        const personId = list[index]?.personId;
        if (personId) {
            onSelectPerson(personId);
        }
    };

    const handleNavigateNext = (personId: string) => {
        const list = sortedResults
            .map((r) => (r?.personId ? { personId: r.personId, selectedTab: 'profile' } : null))
            .filter((r) => !!r);
        const currentIndex = list.findIndex((p) => p.personId === personId);
        if (currentIndex >= 0 && currentIndex < list.length - 1) {
            const nextPersonId = list[currentIndex + 1].personId;
            onSelectPerson(nextPersonId);
            // Navigate to the next person in the list
            setList(list, { personId: nextPersonId, selectedTab: 'profile' }, handleNavigate);
        } else {
            onSelectPerson(null);
            setList(null, null);
        }
    };

    return getProfileAdornments;
};
