import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Header } from '../components/header';
import { ProfileUpdatesNeeded } from '../components/profile-updates-needed';

interface ProfileUpdatesNeededParams {
    page?: string;
}

const ProfileUpdatesNeededPageComponent: React.FC<RouteComponentProps<ProfileUpdatesNeededParams>> = ({ match }) => {
    const page = match.params.page ? parseInt(match.params.page, 10) : 1;
    return (
        <DocumentTitle title="Profile Updates Needed">
            <div id="container">
                <Header title="Profile Updates Needed" />
                <div id="content" className="flex-fill">
                    <ProfileUpdatesNeeded page={page} />
                </div>
            </div>
        </DocumentTitle>
    );
};

export const ProfileUpdatesNeededPage = withRouter(ProfileUpdatesNeededPageComponent);
