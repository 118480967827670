import { IconButton, Paper } from 'material-ui';
import ThumbDown from 'material-ui/svg-icons/action/thumb-down';
import ThumbUp from 'material-ui/svg-icons/action/thumb-up';
import * as React from 'react';

import { ContactChannel } from 'shared/models/contact';
import { JobTitle } from '../containers/job-title';
import { UserActions } from '../containers/user-actions';
import { Avatar } from '../sfc/avatar';
import { PersonProfile } from '../sfc/person-profile';
import { Job, PersonDetails } from '../state';
import { ChannelContacts } from './channel-contacts';
import { PersonCandidacies } from './person-candidacies';
import { PersonExtensionData } from './person-extension-data';
import { PersonFiles } from './person-files';
import { PersonLinks } from './person-links';
import { PersonTags } from './person-tags';

interface PersonJobLabelProps {
    personDetails: PersonDetails;
    job: Job;
    userLabel: {
        jobId: string;
        rating: boolean;
        userId: string;
        createdAt: number;
    };
    createLabel: (personId: string, jobId: string, label: boolean) => void;
    creating: boolean;
    toggleDrawer: () => void;
}

const iconStyle = {
    height: 20,
    width: 20
};

const buttonStyle = {
    background: 'white',
    borderRadius: '50%',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    height: 36,
    marginRight: '20px',
    padding: 0,
    width: 36
};

export class PersonJobLabel extends React.Component<PersonJobLabelProps, undefined> {
    handleLabelCreate = (label: boolean) => () => {
        const { personDetails, job, createLabel } = this.props;
        createLabel(personDetails.person.id, job.id, label);
    };

    render() {
        const { job, userLabel, creating, personDetails } = this.props;
        const details = this.props.personDetails;
        const person = details.person;
        const avatarSize = 90;
        const profileLinks = <PersonLinks personDetails={personDetails} />;
        const emails = details.contacts.filter((c) => c.channel === ContactChannel.Email);
        const emailContacts = (
            <ChannelContacts
                channel={ContactChannel.Email}
                contacts={emails}
                personId={details.person.id}
                details={personDetails}
            />
        );
        const phones = details.contacts.filter((c) => c.channel === ContactChannel.Phone);
        const phoneContacts = (
            <ChannelContacts
                channel={ContactChannel.Phone}
                contacts={phones}
                personId={details.person.id}
                details={personDetails}
            />
        );
        const jobTitle = (
            <div className="job-title">
                <i className="material-icons list-icon">work</i>
                <JobTitle id={job.id} />
            </div>
        );
        const label =
            userLabel && (userLabel.rating === true || userLabel.rating === false) ? userLabel.rating : undefined;
        const labels = (
            <div className="label-buttons">
                <div className="label-button">
                    <IconButton
                        style={Object.assign({}, buttonStyle, { background: label === true ? '#66bb6a' : 'white' })}
                        iconStyle={iconStyle}
                        disabled={creating}
                        onClick={this.handleLabelCreate(label === true ? undefined : true)}
                    >
                        <ThumbUp color={label === true ? 'white' : 'rgba(0, 0, 0, 0.87)'} />
                    </IconButton>
                </div>
                <div className="label-button">
                    <IconButton
                        style={Object.assign({}, buttonStyle, { background: label === false ? '#ef5350' : 'white' })}
                        iconStyle={iconStyle}
                        disabled={creating}
                        onClick={this.handleLabelCreate(label === false ? undefined : false)}
                    >
                        <ThumbDown color={label === false ? 'white' : 'rgba(0, 0, 0, 0.87)'} />
                    </IconButton>
                </div>
            </div>
        );
        const profile = details.profile ? (
            <PersonProfile
                personId={details.person.id}
                profile={details.profile.content}
                viewedAt={details.profile.viewedAt}
            />
        ) : null;
        return (
            <div className="profile profile-person-job-label">
                <Paper>
                    <div className="header">
                        <div className="header-hero">
                            <div className="header-left-menu">
                                <IconButton onClick={this.props.toggleDrawer}>
                                    <i className="material-icons">menu</i>
                                </IconButton>
                            </div>
                            <div className="header-hero-left">
                                <h1>{person.name.full}</h1>
                                <div className="person-job-label">
                                    {jobTitle}
                                    {labels}
                                </div>
                            </div>
                            <div className="header-hero-right">
                                <Avatar entity={person} size={avatarSize} />
                            </div>
                        </div>
                    </div>
                </Paper>
                <div className="content-scroll">
                    <div className="content">
                        <div className="left-col">{profile}</div>
                        <div className="right-col">
                            <div>
                                <PersonExtensionData personId={person.id} jobId={job.id} />
                                {emailContacts}
                                {phoneContacts}
                                <PersonFiles person={person} />
                                {profileLinks}
                                <PersonCandidacies candidates={details.candidates} currentJobId={undefined} />
                                <PersonTags personId={person.id} />
                                <UserActions target={`persons-${person.id}`} context={{}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
