import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { internalClientIds, JobType } from 'shared/common/job-constants';
import { awaitingClientStage } from 'shared/models/job-stages';
import { rocketScreenCompleteStage } from 'shared/models/job-stages';
import { clientFirstRoundStage } from 'shared/models/job-stages';

import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { getStatsMap, Stat, UserRecruitingStats } from '../common/recruiter-stats';
import { SearchTextField } from '../common/search-text-field';
import { FUNNEL_STATS } from '../graphql/queries/recruiter-stats';
import { MetricsVariables } from '../graphql/queries/recruiter-stats';
import { FunnelStats } from '../graphql/queries/recruiter-stats';
import { useLocalStorage } from '../hooks/use-local-storage';
import { usePeriodFunnelCandidates } from '../hooks/use-period-funnel-candidates';
import { CellValue, DataPanel } from './data-panel';
import { DataPanelColumn, dataPanelContainerStyles, selectorStyles } from './data-panel';
import { DurationSelector } from './duration-selector';
import { FunnelStat } from './funnel-stat';
import { Header } from './header';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';
import { UserMetrics } from './metrics/user-metrics';

type Column =
    | 'recruiter'
    | 'outreachSent'
    | 'responseRate'
    | 'phoneScreens'
    | 'submits'
    | 'clientAccepts'
    | 'acceptsCall'
    | 'accepts1kOutreach'
    | 'recruiterBillings'
    | 'amBillings'
    | 'totalBillings'
    | 'commission';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'outreachSent', label: 'Outreach Sent' },
    { id: 'responseRate', label: 'Response Rate' },
    { id: 'phoneScreens', label: 'Phone Screens' },
    { id: 'submits', label: 'Submits' },
    { id: 'clientAccepts', label: 'Client Accepts' },
    { id: 'acceptsCall', label: 'Accepts/Call' },
    { id: 'accepts1kOutreach', label: 'Accepts/1K Outreach' },
    { id: 'recruiterBillings', label: 'Recruiter Billings' },
    { id: 'amBillings', label: 'AM Billings' },
    { id: 'totalBillings', label: 'Total Billings' },
    { id: 'commission', label: 'Commission' }
];

const styles = (theme: Theme) => css`
    ${dataPanelContainerStyles(theme)};

    .stat-main {
        font-weight: 500;
        font-size: 16px;
    }

    .funnel-stat {
        padding: 5px 0;
    }

    .recruiter-name {
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            color: ${theme.palette.primary.main};
        }
    }
`;

export const RecruiterStats: React.FC<{}> = () => {
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'quarter', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>(
        'recruiter-stats-job-types',
        jobTypesForFilter.get('all')
    );
    const [searchText, setSearchText] = React.useState('');
    const allJobTypes = jobTypesForFilter.get('all');
    const excludeJobTypes = allJobTypes.filter((jt) => jobTypes.indexOf(jt) === -1);
    const { data: funnelData } = useQuery<FunnelStats, MetricsVariables>(FUNNEL_STATS, {
        variables: {
            endTime: duration.end,
            excludeClientIds: internalClientIds,
            excludeJobTypes,
            startTime: duration.start
        }
    });
    const { data: funnelBaselineData } = useQuery<FunnelStats, MetricsVariables>(FUNNEL_STATS, {
        variables: {
            endTime: duration.baselineEnd,
            excludeClientIds: internalClientIds,
            excludeJobTypes,
            startTime: duration.baselineStart
        }
    });
    const { setPeriodFunnelCandidatesData } = usePeriodFunnelCandidates();

    const handleStatClick = (label: string, record: UserRecruitingStats, stat: Stat, stage: string) => () => {
        if (stat?.value) {
            setPeriodFunnelCandidatesData({ label, period: duration, user: record.user, stage });
        }
    };

    const moneyFormat = (val: number) => `$${Math.round(val).toLocaleString()}`;

    const statsMap = getStatsMap(funnelData, funnelBaselineData);
    const data =
        funnelData && funnelBaselineData
            ? Array.from(statsMap.dataMap.values()).filter(
                  (r) =>
                      r.submits?.value ||
                      r.clientAccepts?.value ||
                      r.phoneScreens?.value ||
                      r.outreachesSent?.value ||
                      r.totalBillings?.value
              )
            : undefined;

    const sortableColumns = columns.map((c) => c.id);

    const rowFilter = React.useCallback(
        (stat: UserRecruitingStats) => {
            return stat.user.name.toLowerCase().includes(searchText.toLowerCase());
        },
        [searchText]
    );

    const getCellValue = (column: Column) => (record: UserRecruitingStats) => {
        switch (column) {
            case 'recruiter':
                return record.user.name;
            case 'outreachSent':
                return record.outreachesSent.value;
            case 'responseRate':
                return record.outreachResponseRatio.value;
            case 'phoneScreens':
                return record.phoneScreens.value;
            case 'submits':
                return record.submits.value;
            case 'clientAccepts':
                return record.clientAccepts.value;
            case 'acceptsCall':
                return record.callAcceptRatio.value;
            case 'accepts1kOutreach':
                return record.acceptOutreachRatio.value;
            case 'recruiterBillings':
                return record.recruiterBillings.value;
            case 'amBillings':
                return record.amBillings.value;
            case 'totalBillings':
                return record.totalBillings.value;
            case 'commission':
                return record.commission.value;
            default:
                return null;
        }
    };

    const getTotalValue = (column: Column) => {
        switch (column) {
            case 'recruiter':
                return <span className="recruiter-name">Total</span>;
            case 'outreachSent':
                return <span className="stat-main">{statsMap.totals.outreachesSum.toLocaleString()}</span>;
            case 'phoneScreens':
                return <span className="stat-main">{statsMap.totals.phoneScreensSum.toLocaleString()}</span>;
            case 'submits':
                return <span className="stat-main">{statsMap.totals.submitsSum.toLocaleString()}</span>;
            case 'clientAccepts':
                return <span className="stat-main">{statsMap.totals.clientAcceptsSum.toLocaleString()}</span>;
            default:
                return ' - ';
        }
    };

    const getCellRenderer = {
        accepts1kOutreach: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.acceptOutreachRatio}
                description="accepts/1k outreach"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
            />
        ),
        acceptsCall: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.callAcceptRatio}
                description="accepts/call"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
            />
        ),
        amBillings: (_1: CellValue, record: UserRecruitingStats) => (
            <span className="stat-main">{moneyFormat(record.amBillings.value)}</span>
        ),
        clientAccepts: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.clientAccepts}
                description="client accepts"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
                onClick={handleStatClick('Client Accepts', record, record.clientAccepts, clientFirstRoundStage)}
            />
        ),
        commission: (_1: CellValue, record: UserRecruitingStats) => (
            <span className="stat-main">{moneyFormat(record.commission.value)}</span>
        ),
        outreachSent: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.outreachesSent}
                description="outreach emails sent"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
            />
        ),
        phoneScreens: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.phoneScreens}
                description="phone screens"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
                onClick={handleStatClick('Phone calls', record, record.phoneScreens, rocketScreenCompleteStage)}
            />
        ),
        recruiter: (_1: CellValue, record: UserRecruitingStats) => (
            <PageDialogLink
                url={`/user/${record.user.username}/metrics`}
                Component={UserMetrics}
                componentProps={{ username: record.user.username }}
            >
                <span className="recruiter-name">{record.user.name}</span>
            </PageDialogLink>
        ),
        recruiterBillings: (_1: CellValue, record: UserRecruitingStats) => (
            <span className="stat-main">{moneyFormat(record.recruiterBillings.value)}</span>
        ),
        responseRate: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.outreachResponseRatio}
                description="response rate"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
            />
        ),
        submits: (_1: CellValue, record: UserRecruitingStats) => (
            <FunnelStat
                data={record.submits}
                description="submits"
                period={duration.period}
                baselinePeriod={duration.baselinePeriod}
                onClick={handleStatClick('Submissions', record, record.submits, awaitingClientStage)}
            />
        ),
        totalBillings: (_1: CellValue, record: UserRecruitingStats) => (
            <span className="stat-main">{moneyFormat(record.totalBillings.value)}</span>
        )
    };

    const displayedColumnIds: Column[] = [
        'recruiter',
        'outreachSent',
        // 'responseRate',
        'phoneScreens',
        'submits',
        'clientAccepts',
        // 'acceptsCall',
        // 'accepts1kOutreach',
        'recruiterBillings',
        'amBillings',
        // 'totalBillings',
        'commission'
    ];
    const displayedColumns = columns.filter((c) => displayedColumnIds.includes(c.id));

    return (
        <DocumentTitle title="Recruiting Stats">
            <div id="container">
                <Header title="Recruiting Stats" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <div css={selectorStyles}>
                            <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                            <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                            <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                        </div>
                    </div>
                    <div className="data-panel">
                        <DataPanel
                            columns={displayedColumns}
                            data={data}
                            getCellValue={getCellValue}
                            getCellRenderer={getCellRenderer}
                            getTotalValue={getTotalValue}
                            sortableColumns={sortableColumns}
                            filterableColumns={['recruiter']}
                            initialSortColumn="commission"
                            initialSortDirection="desc"
                            rowFilter={rowFilter}
                            hidePagination={true}
                            pageSize={100}
                        />
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
