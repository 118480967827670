import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { ChevronRight, Settings, ViewColumn } from '@material-ui/icons';
import React from 'react';

import { DurationProgress } from '../common/duration-progress';
import { PageDialogLink } from '../common/page-dialog-link';
import { colors, getScoreColor } from '../common/score-ratings';
import { JobCandidatesBoard } from '../containers/job-candidates-board';
import { JobEdit } from '../containers/job-edit';
import { CP_JOB_DETAILS } from '../graphql/queries/candidates';
import { JobWithDetails } from '../graphql/queries/jobs-table';
import {
    CREATE_PROFILE_SCORE,
    DELETE_PROFILE_SCORE,
    PERSON_JOB_SCORES,
    PersonJobScore
} from '../graphql/queries/profile-score';
import { useAIProfileScoreDetails } from '../hooks/use-ai-profile-score-details';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobDetails } from './job-details';
import { StarRating } from './star-rating';

const dialogStyles = (theme: Theme) => css`
    .MuiDialogContent-root {
        border-top: thin solid ${theme.palette.divider};
        border-bottom: thin solid ${theme.palette.divider};
    }

    .MuiDialogTitle-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 24px;
    }
`;

const styles = (theme: Theme) => css`
    padding: 15px;
    margin-bottom: 20px;
    background: white;
    border: thin solid ${theme.palette.divider};
    border-radius: 2px;

    .score {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .MuiSvgIcon-root {
            font-size: 1.25rem;
        }

        .star-rating-value {
            font-size: 14px;
        }
    }

    .cp-job-title {
        max-width: 350px;
        font-size: 14px;
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        color: ${theme.palette.primary.main};

        .job-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .see-more {
        cursor: pointer;
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }
`;

const MIN_SCORE = 3.0;
const DEFAULT_SCORE_COUNT = 5;

export const PersonJobScores: React.FC<{ personId: string; jobId: string }> = ({ personId, jobId }) => {
    const theme = useTheme();
    const [viewingJobId, setViewingJobId] = React.useState<string | null>(null);
    const [viewAllScores, setViewAllScores] = React.useState<boolean>(false);
    const { data, refetch } = useQuery<
        { scores: PersonJobScore[] },
        { personId: string; minScore: number; includeSubmitted: boolean }
    >(PERSON_JOB_SCORES, {
        variables: { personId, minScore: jobId ? MIN_SCORE : 0, includeSubmitted: !jobId }
    });
    const [createScore, { loading: creating }] = useMutation<
        { score: { id: string } },
        { personId: string; jobId: string; score: number }
    >(CREATE_PROFILE_SCORE);
    const [fetchJob, { data: jobData, loading: jobDetailsLoading }] = useLazyQuery<
        { job: JobWithDetails },
        { jobId: string }
    >(CP_JOB_DETAILS);
    const [deleteScore, { loading: deleting }] = useMutation<{}, { id: string }>(DELETE_PROFILE_SCORE);
    const { showScoreDetails } = useAIProfileScoreDetails();
    const { user } = useSession();
    const { setSnackbar } = useSnackbar();

    const handleScoreClick = (score: PersonJobScore) => () => {
        showScoreDetails(score);
    };

    const handleDeleteScore = (scoreId: string) => async () => {
        setSnackbar('Deleting score...');
        await deleteScore({ variables: { id: scoreId } });
        refetch();
        setSnackbar('Score deleted');
    };

    const handleCreateScore = (scoredJobId: string) => async (rating: number) => {
        setSnackbar('Creating score...');
        const result = await createScore({ variables: { personId, jobId: scoredJobId, score: rating } });
        refetch();
        setSnackbar(
            'Score created',
            <Button style={{ color: 'white' }} onClick={handleDeleteScore(result.data.score.id)}>
                Undo
            </Button>
        );
    };

    const handleJobClick = (selectedJobId: string) => () => {
        fetchJob({ variables: { jobId: selectedJobId } });
        setViewingJobId(selectedJobId);
    };

    const handleCloseJobDetails = () => {
        setViewingJobId(null);
    };

    const handleToggleViewAllScores = () => {
        setViewAllScores(!viewAllScores);
    };

    if (!data?.scores?.length) {
        return null;
    }

    const loading = creating || deleting;

    const jobDetailsDialog = viewingJobId ? (
        jobDetailsLoading ? (
            <Dialog open={true} maxWidth="sm" fullWidth={true}>
                <DialogContent style={{ padding: '20px 40px' }}>
                    <DurationProgress />
                </DialogContent>
            </Dialog>
        ) : (
            <Dialog
                open={true}
                maxWidth="md"
                fullWidth={true}
                onClose={handleCloseJobDetails}
                css={dialogStyles(theme)}
            >
                <DialogTitle disableTypography={true}>
                    <Typography variant="h4" component="div">
                        {jobData.job.client.name} - {jobData.job.title}
                    </Typography>
                    <div>
                        <PageDialogLink
                            url={`/job/${jobData.job.id}/board`}
                            Component={JobCandidatesBoard}
                            componentProps={{ jobId: jobData.job.id }}
                        >
                            <Tooltip title="Job Candidates Board">
                                <IconButton href={`/job/${jobData.job.id}/board`}>
                                    <ViewColumn />
                                </IconButton>
                            </Tooltip>
                        </PageDialogLink>
                        <PageDialogLink
                            url={`/job/${jobData.job.id}/edit`}
                            Component={JobEdit}
                            componentProps={{ id: jobData.job.id }}
                        >
                            <Tooltip title="Job Settings">
                                <IconButton href={`/job/${jobData.job.id}/edit`}>
                                    <Settings />
                                </IconButton>
                            </Tooltip>
                        </PageDialogLink>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <JobDetails job={jobData.job} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseJobDetails}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    ) : null;

    const scores = data?.scores?.slice(0, viewAllScores ? data.scores.length : DEFAULT_SCORE_COUNT).map((score) => (
        <div className="score" key={score.id}>
            <div className="cp-job-title" onClick={handleJobClick(score.job.id)}>
                {score.job.client.name}
                <ChevronRight />
                <div className="job-name">{score.job.title}</div>
            </div>
            <div className="rating">
                <StarRating
                    rating={score.score}
                    tooltip={score.scoreExplanation}
                    htmlColor={getScoreColor(score, user).alternate}
                    onClick={handleScoreClick(score)}
                    onChange={loading ? null : handleCreateScore(score.jobId)}
                    hoverHtmlColor={colors.sessionUser.main}
                />
            </div>
        </div>
    ));

    const seeMore =
        data?.scores?.length > DEFAULT_SCORE_COUNT ? (
            <div className="see-more" onClick={handleToggleViewAllScores}>
                {viewAllScores ? 'See less' : `See ${data.scores.length - DEFAULT_SCORE_COUNT} more`}
            </div>
        ) : null;

    return (
        <div css={styles(theme)}>
            {scores}
            {jobDetailsDialog}
            {seeMore}
        </div>
    );
};
