import { css } from '@emotion/core';
import { Typography } from '@material-ui/core';
import { diffLines } from 'diff';
import { isEqual, isNil, sortBy, startCase } from 'lodash';
import React from 'react';

import { htmlToText } from 'shared/common/html-to-text';

import { isHtml } from '../common/utils';
import { JDLLMUpdate } from '../graphql/queries/notifications';

type Primitive = string | number | boolean | null;

// Helper functions
function isPrimitive(value: any): value is Primitive {
    return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

function isObject(value: any): value is object {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
}

function entityDiff(
    oldObject: any,
    newObject: any,
    context: string[] = []
): Array<{
    context: string[];
    newValue: Primitive;
    oldValue: Primitive;
}> {
    const diffs: Array<{
        context: string[];
        newValue: Primitive;
        oldValue: Primitive;
    }> = [];

    if (isEqual(oldObject, newObject)) {
        // Values are identical, no differences
        return diffs;
    }

    if (isNil(oldObject) && isNil(newObject)) {
        return diffs;
    }

    if (oldObject === undefined || oldObject === null) {
        // obj1 is missing, obj2 is present
        if (isPrimitive(newObject)) {
            diffs.push({ context, newValue: newObject, oldValue: null });
        } else if (isObject(newObject)) {
            // Recursively handle nested objects
            diffs.push(...entityDiff({}, newObject, context));
        } else if (Array.isArray(newObject)) {
            // Recursively handle nested arrays
            diffs.push(...entityDiff([], newObject, context));
        } else {
            throw new Error('Unexpected type');
        }
        return diffs;
    }

    if (newObject === undefined || newObject === null) {
        // obj2 is missing, obj1 is present
        if (isPrimitive(oldObject)) {
            diffs.push({ context, newValue: null, oldValue: oldObject });
        } else if (isObject(oldObject)) {
            // Recursively handle nested objects
            diffs.push(...entityDiff(oldObject, {}, context));
        } else if (Array.isArray(oldObject)) {
            // Recursively handle nested arrays
            diffs.push(...entityDiff(oldObject, [], context));
        } else {
            throw new Error('Unexpected type');
        }
        return diffs;
    }

    if (isPrimitive(oldObject) && isPrimitive(newObject)) {
        if (oldObject !== newObject) {
            diffs.push({ context, newValue: newObject, oldValue: oldObject });
        }
    } else if (isObject(oldObject) && isObject(newObject)) {
        // Compare each key in both objects
        const keys = new Set([...Object.keys(oldObject), ...Object.keys(newObject)]);
        for (const key of Array.from(keys)) {
            diffs.push(...entityDiff((oldObject as any)[key], (newObject as any)[key], [...context, key]));
        }
    } else if (Array.isArray(oldObject) && Array.isArray(newObject)) {
        // Compare each element in both arrays
        const maxLength = Math.max(oldObject.length, newObject.length);
        for (let i = 0; i < maxLength; i++) {
            diffs.push(...entityDiff(oldObject[i], newObject[i], [...context, i.toString()]));
        }
    } else {
        // Types are different - use null for old value
        diffs.push(...entityDiff(null, newObject, context));
    }

    return diffs;
}

function standardizeText(text: Primitive): string {
    const wordwrap = 120;
    let result = (text ?? '').toString();
    if (isHtml(result)) {
        result = htmlToText(result, { wordwrap }).replace(/\n{2,}/g, '\n\n');
    }
    return result;
}

function generateJSXFromDiffs(
    diffs: Array<{
        context: string[];
        newValue: Primitive;
        oldValue: Primitive;
    }>
): React.ReactNode {
    const elements: React.ReactNode[] = [];
    let prevContext: string[] = [];

    for (const diff of diffs) {
        const currentContext = diff.context;
        const commonPrefixLength = getCommonPrefixLength(prevContext, currentContext);

        // Output headers for new context levels
        for (let i = commonPrefixLength; i < currentContext.length; i++) {
            const headerVariant = getHeaderVariant(i);
            const content = currentContext[i];
            elements.push(
                <Typography component="div" variant={headerVariant} key={`header-${elements.length}`}>
                    {startCase(content)}
                </Typography>
            );
        }

        const parts = diffLines(standardizeText(diff.oldValue), standardizeText(diff.newValue), {
            ignoreWhitespace: true
        });
        const partsHtml = parts.map((part, index) => {
            const className = part.added ? 'added' : part.removed ? 'removed' : 'same';
            return (
                <span key={index} className={className}>
                    {part.value}
                </span>
            );
        });

        elements.push(
            <Typography component="div" key={`diff-${elements.length}`} variant="body1">
                {partsHtml}
            </Typography>
        );

        // Update prevContext
        prevContext = currentContext;
    }

    return <>{elements}</>;
}

function getCommonPrefixLength(prevContext: string[], currentContext: string[]): number {
    let i = 0;
    while (i < prevContext.length && i < currentContext.length && prevContext[i] === currentContext[i]) {
        i++;
    }
    return i;
}

function getHeaderVariant(index: number): 'h4' | 'h5' | 'h6' {
    if (index === 0) return 'h4';
    if (index === 1) return 'h5';
    return 'h6'; // For index >= 2
}

const styles = css`
    .MuiTypography-h4 {
        margin-top: 20px;
        &:first-of-type {
            margin-top: 0;
        }
    }

    .MuiTypography-h5 {
        margin-top: 15px;
    }

    .MuiTypography-h6 {
        margin-top: 10px;
    }

    .MuiTypography-body1 {
        margin-top: 5px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .same {
        opacity: 0.75;
    }

    .removed {
        border-radius: 4px;
        background: rgb(255, 235, 233);
        text-decoration: line-through;
    }

    .added {
        border-radius: 4px;
        background: rgb(218, 251, 225);
    }

    ul {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 20px;
    }
`;

export const JDDiff: React.FC<{ updates: JDLLMUpdate['updates'] }> = ({ updates }) => {
    const diffs = [
        ...sortBy(entityDiff(updates.client?.old, updates.client?.new, ['client']), 'context'),
        ...sortBy(entityDiff(updates.jobDescription?.old, updates.jobDescription?.new, ['jobDescription']), 'context'),
        ...sortBy(entityDiff(updates.job?.old, updates.job?.new, ['job']), 'context')
    ];
    return <div css={styles}>{generateJSXFromDiffs(diffs)}</div>;
};
