import { css } from '@emotion/core';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { CheckCircleOutline, Star } from '@material-ui/icons';
import React from 'react';

import { interviewTime } from '../../common/timestamp';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useSession } from '../../hooks/use-session';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }
`;

export const ScoreGeneratedNotification: React.FC<{
    person: { id: string; name: string };
    userId: string;
    createdAt: number;
    onComplete: () => void;
}> = ({ person, userId, createdAt, onComplete }) => {
    const theme = useTheme();
    const { user } = useSession();
    const { setList } = useSlides();

    const handleToggleDetails = () => {
        setList([{ personId: person.id }], { personId: person.id });
    };

    const handleMarkComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onComplete();
    };

    const actions =
        user.id === userId ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    const primary = <Typography variant="h6">Scores Generated</Typography>;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            {person.name}
        </Typography>
    );

    return (
        <ListItem css={styles(theme)} onClick={handleToggleDetails} button={true}>
            <ListItemAvatar>
                <Avatar>
                    <Star />
                </Avatar>
            </ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(createdAt)}</span>
                </Typography>
            </div>
            {actions}
        </ListItem>
    );
};
